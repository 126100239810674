import { ThemeRadii } from '@rover/kibble/styles';

import { MarginSystemProps } from '../styledSystemPropTypes';

export type ImageCommonProps = MarginSystemProps & {
  description: string;
  height: string | number;
  width: string | number;
  resizeMethod?: 'cover' | 'contain' | 'center';
  borderRadius?: ThemeRadii;
  onLoad?: (event: onLoadProps) => void;
  eager?: boolean;
};

export const NOT_FORWARDED_PROPS = ['borderRadius', 'marginTop'];

type onLoadProps = { width: number; height: number; uri: string };
